import { convertEnum } from "../enumUtil";
/**
 * 状态
 */
export const EStatus = {
  /** 启用 */
  Enable: 1,

  /** 禁用 */
  Disabled: 2,
};
export const [MStatus, OStatus] = convertEnum([
  [EStatus.Enable, "启用"],
  [EStatus.Disabled, "禁用"],
]);

/**
 * 复习状态
 */
export const EReviewStatus = {
  /** 已完成 */
  Completed: 1,

  /** 未完成 */
  Incomplete: 0,
};
export const [MReviewStatus, OReviewStatus] = convertEnum([
  [EReviewStatus.Completed, "已完成"],
  [EReviewStatus.Incomplete, "未完成"],
]);
