import { get, put, Delete, post } from "../axios";

// 课程管理-单元管理列表
export const courseList = (params) => get(`/web/course/course/list`, params);

// 单元新增
export const courseAddModule = (params) =>
  post(`/web/course/course/addModule`, params);

// 单元编辑
export const courseUpdateModule = (params) =>
  post(`/web/course/course/updateModule`, params);

// 单元查看
export const courseModuleView = (params) =>
  get(`/web/course/course/moduleView/` + params, "");

// 单元删除
export const courseDeleteModule = (params) =>
  Delete(`/web/course/course/deleteModule/` + params, "");

// 复习进度-课次下拉
export const courseClassNumberList = (params) =>
  get(`/web/course/course/classNumberList/` + params, "");
// 复习进度-单元下拉
export const courseModuleList = (params) =>
  get(`/web/course/course/moduleList/` + params, "");
