<template>
  <div class="main-content">
    <avue-crud
      ref="crud"
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">
            <div class="flex align-items marginRight10 marginBottom10">
              <!-- <span class="span marginRight10 width80 textAlignRight"> </span> -->
              <div class="width300">
                <el-select
                  v-model="searchData.moduleId"
                  clearable
                  placeholder="选择单元"
                  @change="getClassNumber"
                >
                  <el-option
                    v-for="(item, index) in unitList"
                    :key="index"
                    :label="item.className"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <!-- <span class="span marginRight10 width80 textAlignRight"> </span> -->
              <div class="width300">
                <el-select
                  v-model="searchData.classNumberId"
                  clearable
                  placeholder="选择课次"
                >
                  <el-option
                    v-for="(item, index) in classNumberList"
                    :key="index"
                    :label="item.classNumberName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <!-- <span class="span marginRight10 width80 textAlignRight"> </span> -->
              <div class="width300">
                <el-select
                  v-model="searchData.state"
                  clearable
                  placeholder="复习状态"
                >
                  <el-option
                    v-for="(item, index) in OReviewStatus"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <!-- <span class="span marginRight10 width80 textAlignRight"> </span> -->
              <div class="width300">
                <el-input
                  v-model="searchData.studentName"
                  placeholder="输入学生姓名"
                  clearable
                  maxlength="50"
                ></el-input>
              </div>
            </div>

            <el-button
              size="medium"
              type="primary"
              @click="handleSearch"
              style="margin-bottom: 10px"
              class="marginLeft10"
              >搜索</el-button
            >
            <el-button
              size="medium"
              type="info"
              @click="handleReset"
              style="margin-bottom: 10px"
              class="marginLeft10 marginRight10"
              >重置</el-button
            >
            <div class="flex align-items marginRight10 marginBottom10">
              <el-button size="medium" type="primary" @click="handleRevert"
                >返回</el-button
              >
            </div>
          </div>
        </div>
      </template>

      <template slot="state" slot-scope="{ row }">
        <div>
          {{
            row.state == EReviewStatus.Completed
              ? MReviewStatus[row.state]
              : row.state == EReviewStatus.Incomplete
              ? MReviewStatus[row.state]
              : ""
          }}
        </div>
      </template>
      <template slot="studenNum" slot-scope="{ row }">
        <el-button type="text" @click="handNum(row)">{{
          row.studenNum
        }}</el-button>
      </template>

      <template slot="menu" slot-scope="{ row }">/ </template>
    </avue-crud>
  </div>
</template>

<script>
import { classReviewPage } from "@/api/user/class";
import { courseClassNumberList, courseModuleList } from "@/api/course/list";
import {
  MReviewStatus,
  OReviewStatus,
  EReviewStatus,
} from "@/enums/beCurrent/EBeCurrent";
export default {
  name: "index",
  data() {
    return {
      MReviewStatus: MReviewStatus,
      OReviewStatus: OReviewStatus,
      EReviewStatus: EReviewStatus,
      searchData: {
        classNumberId: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      id: this.$route.query.id,
      levelId: this.$route.query.levelId,
      tableData: [],
      showLoading: false,
      showLoadingForm: false,
      dialogVisible: false,
      option: {
        title: "",
        titleSize: "h3",
        card: false,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        selection: false,
        reserveSelection: true,
        selectionFixed: true,
        tip: false,
        column: [
          {
            label: "学生姓名",
            prop: "studentName",
          },
          {
            label: "单元名称",
            prop: "moduleName",
          },
          {
            label: "课次",
            prop: "classNumberName",
          },
          {
            label: "班级名称",
            prop: "className",
          },
          {
            label: "学生等级",
            prop: "levelName",
          },
          {
            label: "复习状态",
            prop: "state",
          },
          {
            label: "完成时间",
            prop: "completeTime",
          },
        ],
      },

      unitList: [], //单元下拉
      classNumberList: [], //课次下拉
      newPage: this.$route.query.newPage,
    };
  },
  mounted() {
    this.getUnit();
    // this.getClassNumber();
  },
  methods: {
    onLoad() {
      this.showLoading = true;
      const searchData = {
        ...this.searchData,
      };
      classReviewPage({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...searchData,
        classId: this.id,
        levelId: this.levelId,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },

    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      // console.log('重置')
      this.searchData = {
        classNumberId: "",
        // state: "",
        // name: "",
        // createTime: "",
      };
      this.classNumberList = [];
      this.page.currentPage = 1;
      this.onLoad();
    },

    //输入
    salaryDianChange(e, t) {
      const value = e.target.value;
      this.form[t] = value;
    },

    //获取单元下拉
    getUnit() {
      courseModuleList(this.levelId).then((res) => {
        if (res.code == 200) {
          this.unitList = res.data;
        }
      });
    },
    //获取课次下拉
    getClassNumber(e) {
      console.log("xxxxxxxxxxx");
      this.searchData.classNumberId = "";
      if (e) {
        courseClassNumberList(e).then((res) => {
          if (res.code == 200) {
            this.classNumberList = res.data;
          }
        });
      } else {
        this.classNumberList = [];
      }
    },

    //返回
    handleRevert() {
      // this.$router.go(-1);
      this.$router.push({
        path: "/user/class",
        query: {
          newPage: this.newPage,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
